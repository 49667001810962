import { createBrowserRouter } from 'react-router-dom';

import Dashboard from '../pages/dashboard';
import Login from '../pages/signin';
import Profile from '../pages/profile';
import CustomerList from '../pages/Customer/CustomerList';
import CreateOrder from '../pages/Order/CreateOrder';
import OrderList from '../pages/Order/OrderList';

import PublicWrapper from './PublicWrapper';
import PrivateWrapper from './PrivateWrapper';
import Addcustomer from '../pages/Customer/CustomerList/Addcustomer';
import CustomerOrders from '../pages/Customer/CustomerList/CustomerOrders';
import Editcustomer from '../pages/Customer/CustomerList/Editcustomer';
import updateProfile from '../pages/profile/updateProfile';
import Invoicepdf from '../pages/Invoicepdf';
import ItemDetails from '../pages/ItemDetails';
import Invoice from '../pages/Invoice';

const allRoutes = [
  {
    path: '/',
    element: <PublicWrapper Content={Login} />,
  },
  {
    path: '/login',
    element: <PublicWrapper Content={Login} />,
  },
  {
    path: '/onboarding',
    element: <PublicWrapper Content={Profile} />,
  },
  {
    path: '/profile',
    element: <PrivateWrapper Content={Profile} />,
  },
  {
    path: '/editprofile',
    element: <PrivateWrapper Content={updateProfile} />,
  },
  {
    path: '/dashboard',
    element: <PrivateWrapper Content={Dashboard} />,
  },
  {
    path: '/orders-list',
    element: <PrivateWrapper Content={OrderList} />,
  },
  {
    path: '/customers',
    element: <PrivateWrapper Content={CustomerList} />,
  },
  {
    path: '/selectcustomer',
    element: <PrivateWrapper Content={CustomerOrders} />,
  },
  {
    path: '/addcustomer',
    element: <PrivateWrapper Content={Addcustomer} />,
  },
  {
    path: '/editcustomer',
    element: <PrivateWrapper Content={Editcustomer} />,
  },
  {
    path: '/select-customer',
    element: <PrivateWrapper Content={CreateOrder} />,
  },
  {
    path: '/select-outfit',
    element: <PrivateWrapper Content={CreateOrder} />,
  },
  {
    path: '/orders/:id?/:itemId?',
    element: <PrivateWrapper Content={CreateOrder} />,
  },
  {
    path: '/order_tracking/:tracking_id?/is_invoice=true',
    element: <PublicWrapper Content={Invoice} showHeader={false} />,
  },
  {
    path: '/order_tracking/:tracking_id?',
    element: <PublicWrapper Content={Invoicepdf} showHeader={false} />,
  },
  {
    path: '/item_detail_tracking/:tracking_id?',
    element: <PublicWrapper Content={ItemDetails} showHeader={false} />,
  },
];

const routes = createBrowserRouter(
  allRoutes.map((route) => {
    return route;
  })
);

export default routes;
